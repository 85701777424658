export const handler = (anchor, e) => {
  e.preventDefault();

  const expanded = anchor.getAttribute('aria-expanded') === 'true';
  const answer = document.querySelector(`#${anchor.getAttribute('aria-controls')}`);

  answer.setAttribute('aria-hidden', expanded);
  anchor.setAttribute('aria-expanded', !expanded);
  anchor.blur();
};
