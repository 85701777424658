import { register as registerScrollListener } from './scroll-listener';
import { uniqueId } from './util';
import { getDocHeight, matchesBreakpoint } from './responsive';

const SPEED_FACTOR = 0.8;
const MAX_TRANSFORM_PERCENTAGE = 50;
const BG_SELECTOR = '.js-parallax-background';

const moveBackground = (el, bg) => e => {
  const docHeight = getDocHeight();
  const bounds = el.getBoundingClientRect();
  if (bounds.bottom < 0 || bounds.top > docHeight) {
    return;
  }
  const offset = ((docHeight - bounds.top) / (docHeight + bounds.height));
  bg.style.transform = `translate3d(0, ${offset * MAX_TRANSFORM_PERCENTAGE * SPEED_FACTOR}%, 0)`;
};

export const enhancer = el => {
  const bg = el.querySelector(BG_SELECTOR);

  if (Modernizr.toucheventsfixed || !Modernizr.csstransforms3d || !matchesBreakpoint('medium') || !bg) {
    return;
  }
  // Got a prefix? Too bad.
  if (typeof bg.style.transform === 'undefined') {
    return;
  }

  // Set initial position and show background
  moveBackground(el, bg)();

  // Attach scroll handler
  const scrollHandler = e => requestAnimationFrame(moveBackground(el, bg));
  registerScrollListener(`parallax-${uniqueId()}`, scrollHandler);
};
