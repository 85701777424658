import { debounce } from '@grrr/utils';

/**
 * Responsive breakpoint registry
 */

let docWidth;
let docHeight;

const BREAKPOINT_TINY = 480;
const BREAKPOINT_SMALL = 640;
const BREAKPOINT_SMALL_MEDIUM = 768;
const BREAKPOINT_MEDIUM = 960;
const BREAKPOINT_MEDIUM_LARGE = 1120;
const BREAKPOINT_LARGE = 1280;
const BREAKPOINT_HUGE = 1680;
const BREAKPOINT_NAV_SWITCH = 1050;

const setDocWidth = () => {
  docWidth = window.innerWidth || document.documentElement.clientWidth;
};

const setDocHeight = () => {
  docHeight = window.innerHeight || document.documentElement.clientHeight;
};

export const getDocWidth = () => {
  if (!docWidth) {
    setDocWidth();
  }
  return docWidth;
};

export const getDocHeight = () => {
  if (!docHeight) {
    setDocHeight();
  }
  return docHeight;
};

export const matchesBreakpoint = breakpoint => {
  switch (breakpoint) {
    case 'tiny':
      return getDocWidth() >= BREAKPOINT_TINY;
    case 'small':
      return getDocWidth() >= BREAKPOINT_SMALL;
    case 'smallMedium':
      return getDocWidth() >= BREAKPOINT_SMALL_MEDIUM;
    case 'medium':
      return getDocWidth() >= BREAKPOINT_MEDIUM;
    case 'mediumLarge':
      return getDocWidth() >= BREAKPOINT_MEDIUM_LARGE;
    case 'large':
      return getDocWidth() >= BREAKPOINT_LARGE;
    case 'huge':
      return getDocWidth() >= BREAKPOINT_HUGE;
    case 'navSwitch':
      return getDocWidth() >= BREAKPOINT_NAV_SWITCH;
    default:
      return false;
  }
};

export const getCurrentBreakpoint = () => {
  const breakpoints = ['tiny', 'small', 'smallMedium', 'medium', 'mediumLarge', 'large', 'huge', 'navSwitch'];
  const matches = breakpoints.filter(matchesBreakpoint);
  return matches[matches.length - 1];
};

export const enhancer = () => {
  window.addEventListener('resize', debounce(setDocWidth, 300));
  window.addEventListener('orientationchange', debounce(setDocWidth, 300));
  window.addEventListener('resize', debounce(setDocHeight, 300));
  window.addEventListener('orientationchange', debounce(setDocHeight, 300));
};
