export const enhancer = (el) => {
  const submittedForm = el.querySelector(".acf-form .af-success");
  if (!submittedForm) {
    return;
  }
  el.scrollIntoView({
    behavior: "smooth",
    block: "start",
    inline: "nearest",
  });
};
