/**
 * @NOTE Most utils have moved to the `@grrr/utils` package. Feel free to add
 * missing functions there.
 */

export const getScrollPosition = () => {
  const supportPageOffset = window.pageXOffset !== undefined;
  const isCSS1Compat = (document.compatMode || "") === "CSS1Compat";

  /* eslint-disable no-nested-ternary */
  const x = supportPageOffset
    ? window.pageXOffset
    : isCSS1Compat ? document.documentElement.scrollLeft : document.body.scrollLeft;

  const y = supportPageOffset
    ? window.pageYOffset
    : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;
  return {
    x,
    y,
  };
  /* eslint-enable no-nested-ternary */
};

export const parseJSON = json => {
  try {
    return JSON.parse(json);
  } catch (e) {
    console.warn("This JSON does not appear to be valied");
    console.error(e);
    return undefined;
  }
};

export const htmlToElement = (html) => {
  const placeholder = document.createElement('div');
  placeholder.innerHTML = html;
  return placeholder.children.length === 1
    ? placeholder.children[0]
    : placeholder.children;
};

export const closest = (el, selector) => {
  let matchesFn;

  // find vendor prefix
  [
    'matches',
    'webkitMatchesSelector',
    'mozMatchesSelector',
    'msMatchesSelector',
    'oMatchesSelector',
  ].some(fn => {
    if (typeof document.body[fn] === 'function') {
      matchesFn = fn;
      return true;
    }
    return false;
  });

  // traverse parents
  while (el !== null) {
    const parent = el.parentElement;
    if (parent !== null && parent[matchesFn](selector)) {
      return parent;
    }
    el = parent;
  }

  return null;
};


export const uniqueId = () => Math.random().toString(36).substr(2, 16);

export const map = (a, fn) => Array.prototype.map.call(a, fn);
